import React, { Component } from 'react'
import { FilterContainer, InputText, SearchButton, ContainerButtonModal } from './style'
import '../../../../assets/fontello/css/gt-font.css'

class SearchInput extends Component<{ onSearch: (input: any) => void; openModal: () => void; value: string }, any> {
  constructor(props: any) {
    super(props)
    this.state = { input: this.props.value || '', hasText: Boolean(this.props.value), modalIsOpen: false }
  }

  handleChange = (event: any) => {
    this.setState({ input: event.target.value, hasText: event.target.value.length > 0 })
  }

  handleClick = () => {
    this.props.onSearch(this.state.input)
  }

  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.props.onSearch(this.state.input)
    }
  }

  clearText = () => {
    this.setState({ input: '', hasText: false }, () => {
      this.props.onSearch(this.state.input)
    })
  }

  render() {
    return (
      <div className="container">
        <FilterContainer className="row mlr-0">
          <div>
            <InputText type="text" placeholder="Restaurante" onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={this.state.input} />
            {this.state.hasText ? <i className="icon-grande-table-22" onClick={this.clearText} /> : null}
          </div>
          <SearchButton onClick={this.handleClick}>
            <span className="desktop">BUSCAR</span>
            <i className="icon-grande-table-19 mobile" />
          </SearchButton>
          <ContainerButtonModal onClick={this.props.openModal} className="container-btn-modal">
            <i className="icon-grande-table-20" />
          </ContainerButtonModal>
        </FilterContainer>
      </div>
    )
  }
}

export default SearchInput
