import media from '../../styles/media'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const BreadCrumbContainer = styled.div`
  padding: 15px;
  background-color: #f0f0f0;
  .pl-5 {
    font-family: 'Roboto';
    padding-left: 30px;
    letter-spacing: 1px;
    .link-text {
      color: ${theme.purpleBrown};
      padding-right: 15px;
      text-decoration: underline;
    }
    b,
    i {
      color: ${theme.purpleBrown};
      padding-right: 15px;
      font-family: 'Roboto Bold';
      cursor: default;
      text-transform: capitalize;
    }
  }
`

export const StyledContainer = styled.div`
  color: black;
  font-weight: 300;
  letter-spacing: 1.9px;
  .display-mobile {
    display: flex;
  }
  .display-desktop {
    display: none;
  }
  ${media.sm`  
  .display-mobile{
    display:none
  }
  .display-desktop {
    display: block;
  }
  `};
`

export const StyledRow = styled.div`
  align-items: center;
  margin-top: 0;
  ${media.sm`  
    margin-top: 2rem;
  `};
`
export const StyledRowSecondary = styled.div`
  padding: 5px;
  border: 1px solid #dfdfdf;
  margin-bottom: 20px;
`

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${media.sm`  
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 0rem;
    margin-bottom: 0rem;

  `};
`

export const StyledContainerMap = styled.div`
  height: 285px;
  ${media.md`
    height: 285px;
  `};
`
export const InfoContainer = styled.div`
  margin: 10px 0;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  .title-info {
    font-size: 13px;
    font-weight: 500;
    i {
      font-size: 13px;
      padding-right: 0.5rem;
    }
    img {
      max-width: 22px;
      padding-right: 0.5rem;
      margin-right: 6px;
    }
    a {
      font-size: 13px;
      font-weight: 300;
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  .item-info {
    padding: 0.4rem 1.8rem;
    display: inline-block;
    height: 36px;
    font-size: 13px;
    font-weight: 300;
    a {
      color: inherit;
    }
  }
  @media screen and (min-width: 431px) and (max-width: 1300px) {
    .title-info {
      font-size: 11px;
      a {
        font-size: 11px;
      }
    }
    .item-info {
      font-size: 11px;
      padding-right: 0.8rem;
    }
  }
`
export const StyledRowSlider = styled.div`
  background: #000000;
  ${media.sm`
  background: #343434;
  min-height: 300px;
  `}
`

export const CustomPanel = styled.div`
  margin: 0 0 20px 0;
  background-color: #fff;
  border: 1px solid transparent;

  strong {
    font-weight: 600;
  }

  .panel-heading {
    padding: 10px 15px;
    color: #333;
    background-color: #cacaca;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
  }

  .panel-body {
    padding: 15px;
    background-color: #f0f0f0;
  }

  .select-control-container {
    .select-control__control {
      padding-left: 6px;
      box-shadow: none;
      color: rgb(51, 51, 51);
      background-color: rgb(202, 202, 202);
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      border-radius: 0;
      border-color: rgb(202, 202, 202);
      .select-control__indicators {
        div {
          color: rgb(51, 51, 51);
        }
      }
    }
  }
`
export const MapLinkContainer = styled.div`
  padding: 0.4rem;
  color: ${theme.purpleBrown};
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
`

export const CustomTr = styled.tr`
  vertical-align: top;
  .first-td {
    padding-right: 0.5rem;
  }
`

export const CustomTable = styled.table`
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: 0;
`

export const ViewMore = styled.div`
  padding-top: 0.4rem;
  color: #c59d5f;
  font-size: 11px;
  letter-spacing: 0.85px;
  cursor: pointer;
`
export const CustomButton = styled.button`
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #d15858;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  letter-spacing: 1.08px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  z-index: 10;
  padding: 0;
  width: 100%;
  border-radius: 0;

  ${media.sm`  
    border-radius: 5px;
    position:relative
    z-index: 0;
  `};

  &:hover {
    background-color: #ffffff;
    color: #d15858;
    border: 1px solid #d15858;
  }
`

export const ButtonContainer = styled.div`
  margin-bottom: 20px;
`
