import React from 'react'
import {
  ExtraInfoWrapper,
  Title,
  StyledExtraInfo,
  StyledDescription,
  IconContainer,
  StyledRow,
  ExtraInfoWrapperItem
} from './style'

const PercentImg = require('../../../../../../assets/images/pages/icons/percent-br.svg')
const BellImg = require('../../../../../../assets/images/pages/icons/bell-br.svg')
const DrinkImg = require('../../../../../../assets/images/pages/icons/drink-br.svg')
const GiftImg = require('../../../../../../assets/images/pages/icons/gift-br.svg')
const MenuImg = require('../../../../../../assets/images/pages/icons/menu-br.svg')
const circleImg = require('../../../../../../assets/images/pages/icons/circle.svg')

interface Props {
  title: string
  listItems: any[]
}

const ExtraInfo = ({ title, listItems }: Props) => {
  return (
    <ExtraInfoWrapper>
      <Title>{title}</Title>
      <div className="container-fluid">
        <StyledRow className="row">
          {listItems.map((item, index) => {
            let IconImage = PercentImg
            switch (item.type) {
              case 'special':
                IconImage = MenuImg
                break
              case 'event':
                IconImage = DrinkImg
                break
              case 'booking':
                IconImage = BellImg
                break
              case 'birthday':
                IconImage = GiftImg
                break
            }
            return (
              <StyledExtraInfo key={`${title}-${index}`}>
                <ExtraInfoWrapperItem>
                  {item.type ? (
                    <span>
                      <IconContainer src={IconImage} alt={item.type} />
                    </span>
                  ) : (
                    <span><IconContainer src={circleImg} /></span>
                  )}
                  <StyledDescription>{item.description}</StyledDescription>
                </ExtraInfoWrapperItem>
                { listItems.length !== index + 1 && (<hr></hr>) }
              </StyledExtraInfo>
            )
          })}
        </StyledRow>
      </div>
    </ExtraInfoWrapper>
  )
}

export default ExtraInfo
