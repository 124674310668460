import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const SubsidiarySliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    ${media.sm`
    min-height: 250px;
    `}
    ${media.lg`
    min-height: 300px;
    `}
  }
  .swiper-button {
    background-color: #ffffff;
    width: 45px;
    height: 45px;
    background-size: 18px 18px;
    color: ${theme.purpleBrown};
    position: absolute;
    &.swiper-button-next {
      right: 0;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23231f20'%2F%3E%3C%2Fsvg%3E") !important;
    }
    &.swiper-button-prev {
      left: 0;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23231f20'%2F%3E%3C%2Fsvg%3E") !important;
    }
    ${media.sm`  
    width: 64px;
    height: 64px;
    `};
  }
  .swiper-slide {
    opacity: 0.2;
    &.swiper-slide-active {
      opacity: 0.5;
    }
    &.swiper-slide-next {
      opacity: 1;
    }
    &.swiper-slide-next + div {
      opacity: 0.5;
    }
    @media screen and (max-width: 450px) {
      opacity: 1 !important;
    }
  }
  ${media.sm`
  padding: 20px 0;
  `}
`
