import React, { Component } from 'react'

import { ButtonContainer } from '../style'
import { SubmitButton } from '../../../../StylesPages/loginStyle'
import { navigate, withPrefix } from 'gatsby'

export default class Membership extends Component<{
  closeMembershipModal: any
  hasOptions: boolean
}> {
  constructor(props: any) {
    super(props)
  }

  openEveveModal = () => {
    this.props.closeMembershipModal('eveve')
  }

  render() {
    return (
      <div>
        <div className="row centered">
          <div className="col-10">
            <div className="container be-membership">
              <div className="be-membership-img">
                <i className="icon-tarjeta_visto be-member-icon" />
              </div>
              <div className="lbl lbl-text">
                Descubre todos los beneficios de ser parte del club gourmet Grande Table, ¡Únete
                ahora!
              </div>

              <ButtonContainer className="btn-container">
                <SubmitButton
                  id="GTABLE_REST_MEMBRE"
                  type="button"
                  value="Quiero ser miembro"
                  className="be-membership-btn"
                  onClick={() => navigate('/membership')}
                />
              </ButtonContainer>

              <ButtonContainer
                className={`btn-container ${this.props.hasOptions ? '' : 'hide-btn'}`}
              >
                <SubmitButton
                  id="GTABLE_REST_CONT"
                  type="button"
                  value="Continuar con la reserva"
                  className="be-membership-btn altern-btn"
                  onClick={this.openEveveModal}
                />
              </ButtonContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
