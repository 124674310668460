import React, { Component } from 'react'
import { Container } from '../StylesPages/restaurantStyle'
import { RouteComponentProps } from '@reach/router'
import RestaurantProfile from './restaurant-profile'
const qs = require('qs')

import { RequestGetter } from '../../helpers/request'
import animateScrollTo from 'animated-scroll-to'
import { navigate, Link } from 'gatsby'
import Loading from '../Loading'
import RestaurantSlider from '../RestaurantsSection/components/RestaurantSlider'
import { StyledRowSlider, BreadCrumbContainer } from './style'
import ImageSlider from './components/ImageSlider'
import { GtmEventsLoad } from '../../helpers/gtm'
import { ReservationEvent } from '../../helpers/GTM/ReservationEvent'

class RestaurantPage extends Component<RouteComponentProps<{ slug: string }>, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      restaurant: {
        id: null
      },
      loading: true
    }
  }

  componentDidMount() {
    this.getRestaurant(this.props.slug || '')
  }

  changeEvent = (slug: string) => {
    this.getRestaurant(slug || '')
  }

  getRestaurant = (slug: string) => {
    this.setState({ loading: true }, () => {
      RequestGetter('get', `restaurants/${slug}`).then((data: any) => {
        if (data.data && data.data.id) {
          this.setState(
            {
              restaurant: {
                id: null
              }
            },
            () => {
              if (document.querySelector('#header-id')) {
                animateScrollTo(document.querySelector('#header-id') as HTMLElement)
              }
              this.setState({
                restaurant: data.data,
                loading: false
              })
              GtmEventsLoad(new ReservationEvent('supplierClick', 'click', 'reservation').initialize({item: data.data}))
            }
          )
        } else {
          navigate(`/restaurants`)
        }
      })
    })
  }

  render() {
    const { restaurant, loading } = this.state
    const { getRestaurant } = this
    return loading === true ? (
      <Loading />
    ) : (
      <div>
        <StyledRowSlider className="container-fluid">
          <div className="container">
            <ImageSlider images={restaurant.subsidiaries[0].images} slug={restaurant.name}/>
          </div>
        </StyledRowSlider>
        <BreadCrumbContainer className="bread-crumb">
          <div className="container pl-5">
            <Link className="link-text" to={`/`}>
              Home
            </Link>
            <i className="icon-grande-table-14" />
            <Link className="link-text" to={`/restaurants`}>
              Restaurantes
            </Link>
            <i className="icon-grande-table-14" />
            <b>{restaurant.name.toLowerCase()}</b>
          </div>
        </BreadCrumbContainer>
        <Container>{restaurant.id && <RestaurantProfile restaurant={restaurant} />}</Container>
        <RestaurantSlider
          callback={getRestaurant}
          type="restaurants"
          noId={restaurant.id}
          onChangeEvent={this.changeEvent}
        />
      </div>
    )
  }
}

export default RestaurantPage
