type pixelFacebookProps = {
  restaurant: any
  branch: any
  currentPosition: any
  speciality: string
}

const isUndefined = (variable: string) => {
  return variable ? variable : 'Cerrado'
}

const getSchedule = (branch: any, dayNumber: number, isClosed: number) => {
  return isUndefined(branch.schedule.week[dayNumber].schedule.split(" - ")[isClosed])
}

const pixelFacebookStructure = (props: pixelFacebookProps) => {
  const { restaurant, branch, currentPosition, speciality } = props
  const structureData = `{
    "@context": "https://schema.org",
      "@type": "Restaurant",
      "name": "${restaurant.name}",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${branch.address}",
        "addressLocality": "Quito",
        "addressRegion": "UI",
        "addressCountry": "EC"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": ${currentPosition.lat},
        "longitude": ${currentPosition.lng}
      },
      "url": "${restaurant.website}",
      "telephone": "${branch.phone}",
      "servesCuisine": "${speciality}",
      "priceRange": "$$$",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[0].day}",
          "opens": "${ getSchedule(branch,0,0) }",
          "closes": "${ getSchedule(branch,0,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[1].day}",
          "opens": "${ getSchedule(branch,1,0) }",
          "closes": "${ getSchedule(branch,1,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[2].day}",
          "opens": "${ getSchedule(branch,2,0) }",
          "closes": "${ getSchedule(branch,2,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[3].day}",
          "opens": "${ getSchedule(branch,3,0) }",
          "closes": "${ getSchedule(branch,3,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[4].day}",
          "opens": "${ getSchedule(branch,4,0) }",
          "closes": "${ getSchedule(branch,4,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[5].day}",
          "opens": "${ getSchedule(branch,5,0) }",
          "closes": "${ getSchedule(branch,5,1) }"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "${branch.schedule.week[6].day}",
          "opens": "${ getSchedule(branch,6,0) }",
          "closes": "${ getSchedule(branch,6,1) }"
        }
      ],
      "acceptsReservations": "Si"
  }`
  return structureData
}

export default pixelFacebookStructure
