import React, { Component } from 'react'
import { FilterTitle, CustomLabel, CheckboxContainer, ViewMoreContainer, FilterContainer } from './style'
import '../../../../assets/fontello/css/gt-font.css'

class RestaurantFilters extends Component<
  {
    applyFilters: (input: any, isChecked: any) => void
    specialities: []
    filters: any[]
    minDataToShow: number[]
    isModal: boolean
  },
  any
> {
  generateFilteringGroups = () => {
    let filteringGroups: any[] = []
    this.props.specialities.map((categoryGroup: any, index: number) => {
      const { title, icon, analiticsClass, analiticsDataProperty } = this.showTitle(categoryGroup.name)
      filteringGroups.push(
        <div key={index}>
          <FilterTitle className={this.props.isModal ? 'mobile' : ''}>
            <div className="row ml6">
              <i className={icon} />
              <div className={`padded-title ${title.length > 20 ? 'large' : ''}`}>{title}</div>
            </div>
          </FilterTitle>
          <CheckboxContainer>
            {categoryGroup.categories.slice(0, this.props.minDataToShow[index]).map((speciality: any, index: number) => {
              for (const filter of this.props.filters) {
                if (filter === speciality.name) {
                  return (
                    <div key={index}>
                      <div>
                        {this.showCheckBox(speciality.name, true, analiticsClass, {
                          [analiticsDataProperty]: speciality.name,
                        })}
                      </div>
                    </div>
                  )
                }
              }
              return (
                <div className="checkbox" key={index}>
                  {this.showCheckBox(speciality.name, false, analiticsClass, {
                    [analiticsDataProperty]: speciality.name,
                  })}
                </div>
              )
            })}
          </CheckboxContainer>
          <ViewMoreContainer onClick={() => this.handleViewMore(categoryGroup.categories.length, index)}>
            {this.props.minDataToShow[index] === 3 ? '+ Ver más' : '- Ver menos'}
          </ViewMoreContainer>
          {index < this.props.specialities.length - 1 ? <hr /> : ''}
        </div>
      )
    })
    return filteringGroups
  }

  showTitle = (groupName: string): { title: string; icon: string; analiticsClass: string; analiticsDataProperty: string } => {
    let analiticsTag: string = ''
    let icon: string = ''
    switch (groupName) {
      case 'Tipo de Cocina / Nacionalidad':
        analiticsTag = 'GTABLE_REST_TIPOCO'
        icon = 'icon-cubiertos'
        break
      case 'Momentos Grande Table':
        analiticsTag = 'GTABLE_REST_MOMENT'
        icon = 'icon-copas'
        break
      case 'Especialidad':
        analiticsTag = 'GTABLE_REST_TIPOCO'
        icon = 'icon-chef'
        break
      default: {
      }
    }
    return {
      title: groupName,
      icon,
      analiticsClass: analiticsTag,
      analiticsDataProperty: 'data-type',
    }
  }

  showCheckBox = (categoryName: string, isChecked: boolean, analiticsClass: string, analiticsData: any) => {
    return (
      <CustomLabel>
        <input type="checkbox" value={categoryName} onChange={this.handleOptions} checked={isChecked} className={analiticsClass} {...analiticsData} />
        {categoryName}
        <span className="checkmark" />
      </CustomLabel>
    )
  }

  handleOptions = (event: any) => {
    this.props.applyFilters(event.target.value, event.target.checked)
  }

  handleViewMore = (groupSize: number, buttonIndex: number) => {
    let minDataToShow: any[] = []
    minDataToShow = this.props.minDataToShow
    minDataToShow[buttonIndex] = minDataToShow[buttonIndex] === 3 ? groupSize : 3

    this.setState({ minDataToShow })
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <FilterContainer className={`col-md-12 ${this.props.isModal ? 'modal' : 'desktop'}`}>{this.generateFilteringGroups()}</FilterContainer>
        </div>
      </div>
    )
  }
}
export default RestaurantFilters
