import React from 'react'
import scriptLoader from 'react-async-script-loader'
import { withScriptjs, withGoogleMap, Marker } from 'react-google-maps'

import { StyledGoogleMaps } from './style'

interface Props {
  center: Coord
  currentPosition: Coord
  zoom: number
  handleClick: Function
  markers: any[]
}

interface Coord {
  lat: number
  lng: number
}
const Map = withScriptjs(
  withGoogleMap(({ zoom, center, handleClick, markers }: Props) => {
    return (
      <StyledGoogleMaps
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
        zoom={zoom}
        center={center}
      >
        {markers.map((marker: any) => (
          <Marker
            key={`marker-${marker.id}`}
            position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
            onClick={() => handleClick(Number(marker.latitude), Number(marker.longitude))}
          />
        ))}
      </StyledGoogleMaps>
    )
  })
)

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${process.env.API_GOOGLE}&v=3.exp&libraries=geometry,drawing,places`
])(Map)
