import React, { Component } from 'react'

export default class Eveve extends Component<{ restaurant: any }, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <iframe
        id="GTABLE_RESERV_EVEVE"
        data-store={this.props.restaurant.name}
        src={this.props.restaurant.eveve}
        style={{ height: '410px', width: '100%', border: 0 }}
      />
    )
  }
}
