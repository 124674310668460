import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

const lightBrown = '#c59d5f'

export const InputText = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 5px 0 0 5px;
  background-color: #ffffff;
  color: ${theme.purpleBrown};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px #cbcbcb;
  font-weight: 300;
  font-family: 'Roboto';
  font-size: 0.9rem;
  font-style: normal;
  font-stretch: normal;
  padding: 20px 19px;
  line-height: normal;
  letter-spacing: 1px;
  text-align: justify;
  &:focus {
    border-color: ${lightBrown};
    outline: none;
    box-shadow: 0px 0px 2px 0px ${lightBrown};
  }
  &::placeholder {
    color: #cbcbcb;
  }

  ${media.sm`  
    height: 56px;
  `};
`

export const FilterContainer = styled.div`
  text-align: center;
  position: relative;
  align-items: center;

  &.mlr-0 {
    margin: 0 auto;
  }

  div {
    flex: 1 0 auto;
    margin: 0 auto;
    width: 60%;
    position: relative;
    &:hover {
      box-shadow: 0 0 24px 0 ${lightBrown};
    }
    i {
      position: absolute;
      color: ${lightBrown};
      left: 95%;
      top: 32%;
      cursor: pointer;
    }
  }

  ${media.sm`  
    padding: 2rem;
    align-items: end;
    .desktop{
      display:inline-block;
    }
    .mobile{
      display:none;
    }
  `};

  ${media.md`
    padding: 2rem 12rem;
  `}

  @media screen and (max-width: 767px) {
    div {
      width: 55%;
      i {
        left: 85%;
      }
    }
  }
`

export const SearchButton = styled.button`
  height: 50px;
  width: 60px;
  border-radius: 0 5px 5px 0;
  background-color: ${lightBrown};
  border: solid 0 ${lightBrown};
  color: #ffffff;
  letter-spacing: 0.92px;
  font-weight: 600;
  cursor: pointer;
  vertical-align: middle;
  top: 23px;
  font-family: 'San Francisco Display';
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;

  &:hover {
    box-shadow: 0 0 24px 0 ${lightBrown};
  }
  ${media.sm`  
    margin: auto 0;
    font-size: 16px;
    width: 165px;
    height: 56px;
  `};
  i {
    font-size: 30px;
  }

  @media screen and (max-width: 767px) {
    width: 50px;
    span {
      display: none;
    }
    i {
      font-size: 25px;
    }
  }
`
export const ContainerButtonModal = styled.div`
  &.container-btn-modal {
    &.width-auto {
      width: auto;
    }
    flex: 0;
    left: 1rem;
    border-radius: 5px;
    background-color: ${lightBrown};
    display: none;
    position: relative;
    font-size: 27px;
    padding: 24px 30px;
    .icon-grande-table-20 {
      position: absolute;
      color: #ffffff;
      left: 22%;
      top: 11%;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 50px;
      font-size: 20px;
      margin: 0 0.5rem;
      left: 0.5rem;
      .icon-grande-table-20 {
        left: 27%;
        top: 18%;
      }
    }

    &:hover {
      box-shadow: 0 0 24px 0 ${lightBrown};
    }
    &:focus {
      border-color: ${lightBrown};
      outline: none;
      box-shadow: 0px 0px 2px 0px ${lightBrown};
    }
  }
`
