import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const ButtonContainer = styled.div`
  margin-bottom: 20px;
  input {
    margin: 0 auto;
  }
`

export const CustomButton = styled.button`
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #d15858;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  letter-spacing: 1.08px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  z-index: 10;
  padding: 0;
  width: 100%;
  border-radius: 0;

  ${media.sm`  
    border-radius: 5px;
    position:relative
    z-index: 0;
  `};

  &:hover {
    background-color: #ffffff;
    color: #d15858;
    border: 1px solid #d15858;
  }

  .icon-grande-table-24 {
    padding-right: 0.6rem;
  }
`

export const LogoContainer = styled.div`
  margin-left: 1rem;
  padding: 0.5rem;
  border: solid 1px #dfdfdf;
  width: 120px;
  height: 120px;
  display: block;
  position: relative;
`
export const LogoImage = styled.img`
  max-width: 100%;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
export const TitleStore = styled.div`
  color: #c59d5f;
  text-align: left;
  font-family: DroidSerif;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
`

export const ModalContainer = styled.div`
  color: ${theme.purpleBrown};

  .gt-reservation {
    margin-left: 0;
    margin-right:0;
    padding: 2rem 2rem 0;
  }

  &.confirmation {
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .header {
    padding-top: 0.8rem;
  }
  .control-label {
    padding-top: 10px;
  }
  .direccion-wrapper {
    padding-top: 10px;
    padding-left: 1rem;
    align-items: baseline;
    .address-label {
      padding-right: 0.5rem;
    }
    .address-text {
      padding-left: 0;
    }
    @media screen and (max-width: 450px) {
      .address-label {
        width: 35%;
      }
      .address-text {
        width: 65%;
      }
    }
    @media screen and (min-width: 451px) and (max-width: 767px) {
      .address-label {
        width: 35%;
      }
      .address-text {
        width: 65%;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      .address-label {
        width: 32%;
      }
      .address-text {
        width: 68%;
      }
    }
    @media screen and (min-width: 1101px) {
      .address-label {
        width: 15%;
      }
      .address-text {
        width: 85%;
      }
    }
  }
  .select-control {
    position: relative;
    .select-control-container {
      .select-control__control {
        height: 45px;
      }
      .select-control__indicators {
        div {
          color: rgb(51, 51, 51);
        }
        span {
          background-color: transparent;
        }
      }
    }
  }

  .no-extra-fields {
    display: none;
  }

  .mask-control {
    label {
      width: 100%;
    }
    input {
      width: 100%;
      height: 45px;
      border-radius: 5px;
      border: solid 1px #cbcbcb;
      background-color: #ffffff;
      padding-left: 15px;
    }
  }
  .display-mobile {
    display: flex;
  }
  .display-desktop {
    display: none;
  }
  .container {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
  .form-group {
    .datepicker-style {
      width: 100%;
      height: 45px;
      border-radius: 5px;
      border: solid 1px #cbcbcb;
      background-color: transparent;
      padding-left: 15px;
    }
    i {
      position: absolute;
      top: 49px;
      right: 25px;
      font-size: 16px;
      z-index: -1;
    }
  }
  ${media.sm`
    height: auto;
    
    .display-mobile{
      display:none
    }
    .display-desktop {
      display: flex;
    }

    .form-group{
      .react-datepicker__time-list-item{
        padding:0 !important;
      }
      .styled-mask {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        border: solid 1px #cbcbcb;
        background-color: #ffffff;
        padding-left: 15px;
        &[type=number] {
          -moz-appearance:textfield; /* Firefox */
        }
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }}
    }
  `};
  ${media.md`
    .display-desktop {
      display: block;
    }
  `};

  .close-btn {
    justify-content: flex-end;
    margin-right: 1rem;
    text-align: right;
    
    &.pt-1 {
      padding-top: 1rem;
    }

    .close {
      cursor: pointer;
      z-index: 10;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${theme.purpleBrown};
    }
  }

  .hint-text {
    .hint-title {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.08px;
      padding: 0.8rem 0;
    }
    p {
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
    }
    ul {
      padding-left: 1rem;
    }
  }
`
export const ModalFooter = styled.div`
  width: 100%;
  background-color: #000;
  color: white;
  padding: 15px;
  text-align: center;
`
export const CustomInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: solid 1px #cbcbcb;
  background-color: #ffffff;
  padding-left: 15px;
  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const ButtonReservation = styled.div`
  text-align: center;
  padding-top: 20px;
`
export const CustomButtonReservation = styled.div`
  background: #c59d5f;
  border: solid 1px #c59d5f;
  padding: 0.5rem 2.5rem;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  border-radius: 17.5px;
  letter-spacing: 1.7px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 300;
  margin: auto;

  display: block;
  &:hover {
    background: white;
    color: #c59d5f;
    border: solid 1px #c59d5f;
  }
  ${media.sm`  
  display: inline-block;  
  width: auto;
  position: relative;
  padding: 0.5rem 2.5rem;
  border-radius: 17.5px;
  margin: auto;
`};
`
