import React from 'react'
import { withPrefix } from 'gatsby'
import { Router } from '@reach/router'
import RestaurantsSection from '../components/RestaurantsSection'
import Layout from '../components/Layout'
import RestaurantPage from '../components/RestaurantProfile'

export default () => (
  <Layout themeType="dark" currentPage={'restaurants'}>
    <Router>
      <RestaurantsSection withImage={true} path={withPrefix('/restaurants')} />
      <RestaurantPage path={withPrefix('/restaurants/:slug')}/>
    </Router>
  </Layout>
)
