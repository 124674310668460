import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import media from '../../../../../styles/media'

export const ModalContainerProcess = styled.div`
  &.eveve {
    height: auto;
  }

  &.membership {
    height: 400px;
  }

  .centered {
    justify-content: center;
  }

  .roboto-bold {
    font-family: 'Roboto Bold';
  }

  .roboto-light {
    font-family: 'Roboto Light';
  }

  .close-btn {
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 1rem;
    text-align: right;
    .close {
      cursor: pointer;
      z-index: 10;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${theme.purpleBrown};
    }
  }

  .lbl {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: ${theme.purpleBrown};
  }

  .lbl-text {
    text-align: center;
  }

  .lbl-input {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .input-st {
    border-radius: 5px;
    border: solid 1px #cbcbcb;
    width: 100%;
    height: 40px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.error {
      border-color: red;
    }
  }

  .btn-container {
    margin-top: 1rem;

    &.hide-btn {
      display: none;
    }
  }

  .be-membership {
    text-align: center;

    .be-membership-img {
      width: 108px;
      height: 108px;
      background-color: #c59d5f;
      color: #ffffff;
      margin: 1rem auto;
      border-radius: 50%;
      padding-top: 5px;

      .be-member-icon {
        font-size: 58px;
        padding-right: 20px;
      }
    }

    .be-membership-btn {
      &.altern-btn {
        background-color: ${theme.mainColor};
        color: ${theme.secondaryColor};
        padding-left: 1rem;
        padding-right: 1rem;
        &:hover {
          background: ${theme.secondaryColor};
          color: ${theme.mainColor};
        }
      }
      &.chef {
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
  }

  ${media.sm`
  .padding-46 {
    padding-left: 46px;
    padding-right: 46px;
  }
  `}
`
