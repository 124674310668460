import React from 'react'

import {
  ProfileWrapper,
  LogoImage,
  LogoContainer,
  Description,
  TitleStore,
  SpecialityContainer
} from './style'
import ExtraInfo from './components/ExtraInfo'

interface SliderProps {
  logo: string
  website?: string
  facebook?: string
  instagram?: string
  branches: any
  description: any
  benefits: any
  restrictions: any
  restaurantName: string
  speciality: string
  getActualBranch?: any
}

class Office extends React.Component<SliderProps> {
  render() {
    const { logo, description, benefits, restrictions, restaurantName, speciality } = this.props
    return (
      <ProfileWrapper className="row">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 display-desktop">
              <LogoContainer>
                <LogoImage src={logo} alt={'logo-' + restaurantName + '-Grande Table'} />
              </LogoContainer>
            </div>
            <div className="col-md-8 col-lg-9">
              <TitleStore>{restaurantName ? restaurantName.toLowerCase() : ''}</TitleStore>
              {speciality && (
                <SpecialityContainer>
                  <i className="icon-grande-table-15" style={{ fontSize: '21px' }} />
                  {speciality}
                </SpecialityContainer>
              )}
              <Description>{description !== null && description}</Description>
              <ExtraInfo title="Beneficios" listItems={benefits} />
              {restrictions.length > 0 && (
                <ExtraInfo
                  title="Restricciones"
                  listItems={restrictions}
                />
              )}
            </div>
          </div>
        </div>
      </ProfileWrapper>
    )
  }
}
export default Office
