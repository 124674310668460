import styled from 'styled-components'
import theme from '../../../../../../styles/theme'

export const ExtraInfoWrapper = styled.div`
  text-align: left;
  color: ${theme.purpleBrown};
  padding-top: 15px;
`
export const Title = styled.h5`
  margin: 0 525px 16px 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.purpleBrown}:;
`
export const StyledExtraInfo = styled.div`
  width: 80%;
  hr {
    border: solid 1px #e4e4e4;
  }
`

export const IconContainer = styled.img`
  width: 24px;
`
export const StyledDescription = styled.div`
  color: ${theme.purpleBrown};
  margin: 16px 0 16px 8px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
`
export const StyledRow = styled.div`
  justify-content: left;
`

export const ExtraInfoWrapperItem = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 4px 0;
  }
`
