import { IPageEvent, PageEventProps } from './IPageEvent'

export class ReservationEvent implements IPageEvent {
  private readonly TypeEvent: string
  private readonly ActionField: string
  private readonly GroupActionEvent: string

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent
    this.ActionField = actionField
    this.GroupActionEvent = groupActionEvent
  }

  initialize({ item, data }: { item: any; data?: any }): PageEventProps {
    const categories =
      (item.categories && item.categories.length > 0 && item.categories.map((x: any) => x.name)) ||
      ''
    return {
      event: this.TypeEvent,
      [this.TypeEvent]: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          ...this.getCustomerReservation(data),
          ...this.getSupplier(item, categories),
        },
      },
    }
  }

  getCustomerReservation(data: any): any {
    if (data) {
      const { customer_id, identification, name, lastName, mobile, email } = data
      return {
        customer: {
          customer_id,
          identification,
          name: name + ' ' + lastName,
          mobile,
          email,
        },
      }
    }
  }

  getSupplier(item: any, categories?: any): any {
    if (item) {
      const { id, name, slug } = item
      return {
        item: {
          id,
          name,
          slug,
          categories,
        },
      }
    }
  }
}
