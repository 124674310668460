import styled from 'styled-components'
import media from '../../styles/media'

const BgRestaurants = require('../../assets/images/pages/bg-restaurants.jpg')

export const StyledContainer = styled.div`
  background-image: url(${BgRestaurants});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-attachment: fixed;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 53vh;
  ${media.sm`
  min-height: 77vh;
  .display-mobile{
    display:none
  }
  .display-desktop {
    display: block;
  }
  `};
  ${media.md`
  min-height: 73vh;
  `}
  ${media.lg`
  min-height: 76vh;
  `}
`

export const StyledRow = styled.div`
  justify-content: left;
`

export const StyledFilter = styled.div`
  display: flex !important;
  text-align: left;
  &.right-aligned {
    justify-content: right;
    -ms-flex-pack: end;
  }
  .no-padding-right {
    padding-right: 0;
  }
  .select-control-container {
    .select-control__control {
      padding-left: 6px;
      box-shadow: none;
      color: #ffffff;
      background-color: transparent;
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      border-radius: 5px;
      border-color: #ffffff;
      .select-control__value-container {
        .select-control__single-value {
          color: #ffffff;
        }
      }
      .select-control__indicators {
        div {
          color: #ffffff;
        }
        span {
          background-color: transparent;
        }
      }
      .select-control__indicators {
        div {
          color: #ffffff;
        }
        span {
          background-color: transparent;
        }
      }
    }
    .select-control__menu {
      color: #000000;
    }
    .select-control__menu {
      color: #000000;
    }
  }

  ${media.sm`  
    text-align: right;
  `};
  &.display-desktop {
    @media screen and (max-width: 430px) {
      display: none !important;
    }
  }
  &.modal {
    @media screen and (max-width: 430px) {
      padding-bottom: 1rem;
      margin-right: 1rem;
    }
  }
`
export const TotalRestaurant = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.08px;
  span {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;
  }
`
export const Close = styled.i`
  font-size: 22px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
  color: #ffffff;
`

export const ModalTitleContainer = styled.div`
  padding: 3rem 0;
  font-family: DroidSerif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.17px;
  text-align: center;
  color: #ffffff;
`
export const RestaurantContainer = styled.div`
  padding: 10px 0;
`

export const FilterButton = styled.div`
  border-radius: 17.5px;
  border: solid 1px #ffffff;
  background: transparent;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 430px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
`
export const ActiveFilters = styled.div`
  padding-top: 1rem;
  cursor: pointer;
  span {
    letter-spacing: 1px;
    padding-left: 0;
    padding-right: 2rem;
    color: #c59d5f;
    width: 93px;
    height: 18px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: justify;
    i {
      font-size: 10px;
      padding-left: 0.3rem;
    }
  }
`
export const CustomNoResult = styled.div`
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.69px;
  text-align: center;
  color: #ffffff;
  padding-top: 5%;
  min-height: 53vh;
  animation: fade-in 1s;
  ${media.sm`
    min-height: 57vh;`}
`
