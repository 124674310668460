import styled from 'styled-components'
import media from '../../../../styles/media'

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  .display-mobile {
    display: flex;
  }
  .display-desktop {
    display: none;
  }
  ${media.sm`  
  .display-mobile{
    display:none
  }
  .display-desktop {
    display: block;
  }
  `};
`
export const LogoContainer = styled.div`
  border: solid 1px #dfdfdf;
  width: 100%;
  span {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`
export const LogoImage = styled.img`
  max-width: 100%;
  vertical-align: middle;
`
export const Description = styled.div`
  text-align: left;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 1px;
`
export const TitleStore = styled.div`
  color: #c59d5f;
  text-align: left;
  text-transform: capitalize;
  padding-top: 15px;
  font-size: 30px;
  font-family: DroidSerif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  ${media.sm`  
    padding-top: 0;
  `};
`
export const SpecialityContainer = styled.div`
  padding-top: 0.4rem;
  padding-bottom: 20px;
  letter-spacing: 1px;
  text-align: left;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  i:before {
    margin-left: 0;
  }
`
