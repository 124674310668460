import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

import { ButtonContainer } from '../style'
import { SubmitButton } from '../../../../StylesPages/loginStyle'
import { StyledError } from '../../../../Collapse/stepped/style'
import { validateIdentification } from '../../../../../utils/validateIdentification'

const LoadingImg = require('../../../../../assets/images/general/loading.svg')

export default class Identification extends Component<
  {
    restaurant: any
    closeIdentificationModal: any
    retrieveFields: any
    retrieveCustomerId: any
    retrieveIdentification: any
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      loading: true,
      customerId: null
    }
  }

  componentDidMount() {
    this.checkSeason()
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    if (this.handleValidation()) {
      this.checkCustomer()
    }
  }

  handleValidation = () => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'El número de identificación es requerido.'
    } else {
      const validIdentification = validateIdentification(fields['identification'], 'C')
      if (validIdentification.error){
        errors['identification'] =  validIdentification.message
        formIsValid = false
      }
    }
    
    this.setState({ errors })
    return formIsValid
  }

  checkCustomer = () => {
    axios
      .get(`${process.env.API_URL}reservations/check-customer`, {
        params: { identification: this.state.fields.identification }
      })
      .then(response => response.data)
      .then(data => {
        const { customerId, identification, name, lastName, email, phone} = data
        if (customerId) {
          this.setState({ customerId: customerId, fields: { identification, name, lastName, email, mobile: phone }, isCustomer: true })
          this.checkEveveRestaurant()
          return
        }
        this.props.retrieveIdentification(this.state.fields.identification)
        this.props.closeIdentificationModal('membership')
      })
  }

  checkEveveRestaurant = () => {
    const { restaurant } = this.props
    if (restaurant.eveve) {
      this.openEveveModal()
      return
    }
    this.openReservationModal()
  }

  checkSeason = () => {
    axios
      .post(
        `${process.env.API_URL}season/check`,
        { currentdate: moment().format('YYYY-MM-DD') },
        {}
      )
      .then(response => response.data)
      .then(data => {
        if (data.valid) {
          this.checkEveveRestaurant()
        }
        this.setState({ loading: false })
      })
  }

  openEveveModal = () => {
    this.props.closeIdentificationModal('eveve')
  }

  openReservationModal = () => {
    this.props.retrieveFields(this.state.fields)
    this.props.retrieveCustomerId(this.state.customerId)
    this.props.retrieveIdentification(this.state.fields.identification)
    this.props.closeIdentificationModal('reservation')
  }

  render() {
    const { errors, fields, loading } = this.state
    return loading ? (
      <div>
        <div className="row centered">
          <img src={LoadingImg} />
        </div>
      </div>
    ) : (
      <div>
        <div className="row centered">
          <div className="col-12 col-sm-10">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="lbl lbl-text">
                Para continuar con el proceso de reserva ingresa tu número de cédula
              </div>
              <div>
                <div className="lbl lbl-input">Ingresa tu número de identificación</div>
                <input
                  name="identification"
                  value={fields['identification'] || ''}
                  className={`input-st ${errors['identification'] ? 'error' : ''}`}
                  type="text"
                  onChange={this.handleUpdate.bind(this, 'identification')}
                />
                {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
              </div>
              <ButtonContainer className="btn-container">
                <SubmitButton
                  id="GTABLE_REST_REARESERVA"
                  data-store={this.props.restaurant.name}
                  type="submit"
                  value="Realizar Reserva"
                />
              </ButtonContainer>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
