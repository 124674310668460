import React, { Component } from 'react'
import { ModalFooter, TextContainer, ModalComponentsWrapper } from './style'

import Button from '../../../../../components/Button'
import Check from '../../../../../assets/images/general/check.jpg'

export default class ConfirmReservation extends Component<{ restaurantName: string }> {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <ModalComponentsWrapper id="GTABLE_REST_CONF" data-store={this.props.restaurantName}>
        <div className="row no-side-margins">
          <TextContainer className="col-md-12">
            <img src={Check} />
            <div>
              Tu solicitud de reserva está
              <br /> siendo procesada.
            </div>
          </TextContainer>
        </div>
        <ModalFooter>Recibirás un correo con la confirmación de tu reserva.</ModalFooter>
        <div className="container-fluid">
          <div className="row footer-button">
            <TextContainer>
              <Button text="aceptar" link="/" />
            </TextContainer>
          </div>
        </div>
      </ModalComponentsWrapper>
    )
  }
}
