import styled from 'styled-components'

export const Container = styled.div`
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`
export const TextContainer = styled.div`
  background: white;
`

export const Text = styled.h3`
  color: black;
  text-align: center;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 300;
  letter-spacing: 1.2px;
`
