import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import '../../../../assets/fontello/css/gt-font.css'
import { SubsidiarySliderContainer } from './style'
import { Flip } from 'react-toastify'

interface SliderProps {
  images: any
  slug: string
}

class ImageSlider extends Component<SliderProps> {
  render() {
    const { images, slug } = this.props

    const params = {
      effect: 'coverflow',
      loop: true,
      slidesPerView: 3,
      spaceBetween: 30,
      grabCursor: true,
      speed: 2700,
      autoplay: {
        delay: 2700,
        disableOnInteraction: false
      },
      coverflowEffect: {
        rotate: 30,
        stretch: 0,
        depth: 350,
        modifier: 1,
        slideShadows: false
      },
      navigation: {
        nextEl: '.swiper-button.swiper-button-next',
        prevEl: '.swiper-button.swiper-button-prev'
      },
      breakpoints: {
        991: {
          slidesPerView: 3,
          spaceBetween: 5,
          effect: 'coverflow'
        },
        767: {
          slidesPerView: 1,
          effect: Flip
        }
      }
    }

    return (
      <SubsidiarySliderContainer>
        <Swiper {...params}>
          {images &&
            images.map((image: any) => {
              return (
                <div key={image.id}>
                  <img src={image.url} alt={image.id + '-' + slug +'-Grande Table'}/>
                </div>
              )
            })}
        </Swiper>
      </SubsidiarySliderContainer>
    )
  }
}

export default ImageSlider
