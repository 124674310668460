import styled from 'styled-components'
import media from '../../../../styles/media'

const lightBrown = '#c59d5f'

export const FilterContainer = styled.div`
  &.desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`
export const FilterTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.08px;
  i {
    font-size: 21px;
  }
  .padded-title {
    margin-top: 0.5rem;
    padding-left: 5px;
    @media screen and (min-width: 451px) and (max-width: 767px) {
      &.large {
        width: 80%;
      }
    }
  }
  &.mobile {
    color: #ffffff;
  }
  .ml6 {
    margin-left: -6px;
  }
  ${media.sm`
    font-size: 10px;
  `}
  ${media.md`
    font-size: 13px;
  `}
`

export const CustomHr = styled.div`
  border: 1px solid #898989;
  margin-bottom: 12px;
`
export const CustomLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 1rem 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    border: solid 1px #ffffff;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${lightBrown};
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${lightBrown};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const CheckboxContainer = styled.div`
  padding-top: 4px;
`
export const ViewMoreContainer = styled.div`
  padding-left: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: ${lightBrown};
  cursor: pointer;
`
