import { withPrefix } from 'gatsby'
import React, { Component, createRef } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { RequestGetter } from '../../helpers/request'
import withLocation from '../AuthSection/withLocation'
import { HelmetComponent } from '../HelmetComponent'
import Loading from '../Loading'
import RestaurantsBar from '../RestaurantsBar'
import RestaurantFilters from './components/RestaurantFilters'
import RestaurantItem from './components/RestaurantItem'
import SearchInput from './components/SearchInput'
import { ActiveFilters, Close, FilterButton, ModalTitleContainer, RestaurantContainer, StyledContainer, StyledFilter, StyledRow, TotalRestaurant } from './style'

interface RestaurantsSectionState {
  restaurants: any[]
  cities: any[]
  specialities: []
  minDataToShow: number[]
  loading: boolean
  cityId: number
  modalIsOpen: boolean
  searchInput: string
  order: any
  filters: any[]
  total: number
  lastPage: number
  currentPage: number
}

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 101,
    padding: '0 0 10rem 0',
    backgroundColor: '#242424',
  },
}

const customStylesLoading = {
  content: {
    ...customStyles.content,
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
}

Modal.setAppElement('#___gatsby')
let lastScrollY = 0

class RestaurantsSection extends Component<{ callback?: Function; withImage?: boolean; noId?: number | null; search: object }, any> {
  state: RestaurantsSectionState = {
    restaurants: [],
    cities: [],
    specialities: [],
    minDataToShow: [],
    loading: true,
    cityId: -1,
    modalIsOpen: false,
    searchInput: '',
    order: 'asc',
    filters: [],
    total: 0,
    lastPage: 0,
    currentPage: 0,
  }
  constructor(props: any) {
    super(props)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  nav = createRef<HTMLDivElement>()
  page = 1

  handleScroll = () => {
    lastScrollY = window.scrollY
    if (lastScrollY >= 1024 * this.page && this.page < this.state.lastPage) {
      this.page += 1
      this.search(this.page)
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  removeFilter = (filterToRemove: any) => {
    const { filters } = this.state
    this.page = 1
    this.setState({ restaurants: [], filters: filters.filter((filter) => filter !== filterToRemove) }, () => {
      this.search()
    })
  }

  handleSearch = (input: any) => {
    this.setState({ restaurants: [], searchInput: input }, () => {
      if (input === '') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'GTABLE_SEARCH_WORD',
          word: input,
        })
      }

      this.search()
    })
  }

  applyFilters = (input: any, isChecked: boolean) => {
    const { filters } = this.state
    this.page = 1
    const newFilters = isChecked ? [...filters, input] : filters.filter((option) => option !== input)
    this.setState({ restaurants: [], filters: newFilters }, () => {
      this.search()
    })
  }

  componentDidMount() {
    const { search } = this.props
    this.getCities().then((selectableCities) => {
      this.setState({ cities: selectableCities })
      this.getSpecialities()

      if (Object.entries(search).length !== 0 && search.constructor !== Object) {
        this.searchByUrlParams(search)
        return
      }
      this.search()
    })
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  getCities = () => {
    return RequestGetter('post', 'cities-by-module', { restaurant: true })
      .then((data: any) => data.data)
      .then((cities: any[]) => {
        let selectableCities: any[] = cities.map((city: any) => ({
          value: city.id,
          label: city.name,
        }))
        selectableCities = [...[{ value: '-1', label: 'Todas' }], ...selectableCities]
        return selectableCities
      })
  }

  getSpecialities = () => {
    RequestGetter('get', 'specialities').then((data: any) => {
      const specialities = data.data.filter((elem: any) => elem.id != 3)
      const filteringGroupsNumber = specialities.length
      this.setState({
        specialities,
        loading: false,
        minDataToShow: this.showCheckboxes(filteringGroupsNumber),
      })
    })
  }

  showCheckboxes = (filteringGroupsNumber: number): number[] => {
    let constantsArray: any[] = []
    for (let i = 0; i < filteringGroupsNumber; i++) {
      constantsArray.push(3)
    }
    return constantsArray
  }

  handleCity = (selectedCity: any) => {
    this.page = 1
    this.setState({ restaurants: [], cityId: parseInt(selectedCity.value) }, () => {
      this.search()
    })
  }

  handleOrder = (selectedOrder: any) => {
    this.setState({ order: selectedOrder.value }, () => {
      this.search()
    })
  }

  search = (page = 1) => {
    const { order, cityId, searchInput, filters } = this.state
    const url = `restaurants/filter?page=${page}`
    this.setState({ loading: page === 1 })
    RequestGetter('get', url, null, {
      withoutId: this.props.noId,
      order,
      filters,
      cityId: cityId == -1 ? null : cityId,
      name: searchInput || null,
    }).then((data: any) => {
      data.data.forEach((restaurant: any) => {
        if (restaurant.pagination) {
          this.setState({
            total: restaurant.pagination[0].total,
            lastPage: restaurant.pagination[0].last_page,
            currentPage: restaurant.pagination[0].current_page,
          })
        }
      })
      this.setState(
        {
          restaurants: [...this.state.restaurants, ...data.data],
          loading: false,
        },
        () => history.pushState({}, 'Results for restaurants', this.generateUrlParams())
      )
    })
  }

  generateUrlParams = (): string => {
    const { order, cityId, cities, searchInput, filters } = this.state
    let urlParams = ''
    let urlFilters = ''
    let urlCity = ''
    let urlOrder = ''
    let urlWord = ''
    if (filters && filters.length > 0) {
      urlFilters = `filters=${filters.join()}`
    }
    if (cityId && cityId != -1) {
      let city = cities.filter((cityOption) => cityOption.value == cityId)[0].label
      urlCity = `city=${city}`
    }
    if (order) {
      urlOrder = `order=${order}`
    }
    if (searchInput) {
      urlWord = `word=${searchInput}`
    }
    urlParams = [urlFilters, urlCity, urlOrder, urlWord].filter(Boolean).join('&')
    return withPrefix(`/restaurants?${urlParams}`)
  }

  searchByUrlParams = (search: any) => {
    let cityId: number = search.city ? this.state.cities.filter((cityOption) => cityOption.label.toLowerCase() == search.city.toLowerCase())[0].value : null
    let order: string = search.order ? search.order : 'asc'
    let filters: any[] = search.filters ? search.filters.split(',') : []
    let capitalizedFilters = filters.map((filter) => filter.charAt(0).toUpperCase() + filter.slice(1))
    this.setState(
      {
        order,
        city: cityId,
        searchInput: search.word,
        filters: capitalizedFilters,
      },
      () => this.search()
    )
  }

  groupSpecialties = (specialties: any[]): any[] => {
    let groups: any[] = specialties.filter((elem) => elem.group_id != 3).map((elem) => elem['group'])
    let uniqueGroups = groups
      .map((elem) => elem['id'])
      .map((elem, index, final) => final.indexOf(elem) === index && index)
      .filter((elem: number) => groups[elem])
      .map((elem: number) => groups[elem])
    let currentGroups = uniqueGroups.map((group) => ({
      id: group.id,
      group: group.name,
      icon: this.showIcon(group.name),
      categories: this.concatenateCategories(specialties, group.id),
    }))
    return currentGroups
  }

  showIcon = (groupName: string): string => {
    switch (groupName) {
      case 'Tipo de Cocina / Nacionalidad':
        return 'icon-cubiertos'
      case 'Momentos Grande Table':
        return 'icon-copas'
      case 'Especialidad':
        return 'icon-chef'
      default:
        return ''
    }
  }

  concatenateCategories = (specialties: any[], groupId: number): string => {
    const categories = specialties
      .filter((specialty: any) => groupId == specialty.group_id)
      .map((specialty: any) => specialty.name)
      .join(', ')
    return categories
  }

  render() {
    const { restaurants, loading, cities, cityId, order, filters, specialities, minDataToShow, modalIsOpen, total, searchInput } = this.state

    const { withImage } = this.props

    const selectedCity = cities.filter((cityItem) => cityItem.value == cityId)[0]
    const sorting = [
      { value: 'asc', label: 'A - Z' },
      { value: 'desc', label: 'Z - A' },
    ]
    const selectedSorting = sorting.filter((sortingItem) => sortingItem.value == order)

    return (
      <div className="row no-side-margins width-100">
        <HelmetComponent
          title="Reservas - Grande Table"
          description="Reserva en los mejores establecimientos y disfruta de descuentos de hasta el 25% todos los días en toda la cuenta."
        />
        {withImage === true && <RestaurantsBar onlyImage={true} />}
        <StyledContainer id="restaurants-sec">
          {loading ? (
            <Loading />
          ) : (
            <>
              <SearchInput onSearch={this.handleSearch} openModal={this.openModal} value={searchInput} />
              <div className="container">
                <div className="row">
                  <div className="col-md-3 display-desktop">
                    <RestaurantFilters applyFilters={this.applyFilters} specialities={specialities} filters={filters} minDataToShow={minDataToShow} isModal={false} />
                  </div>
                  <div className="col-md-9">
                    {filters.length > 0 && (
                      <div className="row">
                        <ActiveFilters className="col-md-12">
                          {filters.map((option) => {
                            return (
                              <span key={option.id} onClick={() => this.removeFilter(option)}>
                                {option}
                                <i className="icon-grande-table-21" />
                              </span>
                            )
                          })}
                          <hr />
                        </ActiveFilters>
                      </div>
                    )}
                    <div className="row" style={{ paddingTop: '1rem' }}>
                      <TotalRestaurant className="col-md-4">
                        {total} Restaurantes <br />
                        <span>Disponibles</span>
                      </TotalRestaurant>

                      <StyledFilter className="col-md-8 display-desktop right-aligned">
                        <div className="col-md-4 no-padding-right">
                          <Select
                            isSearchable={false}
                            className="select-control-container"
                            classNamePrefix="select-control"
                            name="sort"
                            onChange={this.handleOrder}
                            defaultValue={sorting[0]}
                            options={sorting}
                            value={selectedSorting}
                          />
                        </div>
                        <div className="col-md-4 no-padding-right">
                          <Select
                            isSearchable={false}
                            className="select-control-container"
                            classNamePrefix="select-control"
                            name="city"
                            onChange={this.handleCity}
                            defaultValue={cities[0]}
                            options={cities}
                            value={selectedCity}
                          />
                        </div>
                      </StyledFilter>
                    </div>

                    <StyledRow className="row" ref={this.nav}>
                      {restaurants.map((restaurant) => {
                        if (!restaurant.pagination) {
                          let specialties = this.groupSpecialties(restaurant.specialities)
                          return (
                            <RestaurantItem
                              key={`restaurant-${restaurant.id}`}
                              image={restaurant.bg_image}
                              icon={restaurant.icon}
                              title={restaurant.name}
                              slug={restaurant.slug}
                              specialties={specialties}
                              hasEveve={restaurant.eveve}
                            />
                          )
                        }
                      })}
                    </StyledRow>
                  </div>
                </div>
              </div>

              <Modal isOpen={modalIsOpen} onRequestClose={this.closeModal} style={customStyles}>
                <Close className="icon-grande-table-21" onClick={this.closeModal} />
                <div className="container-fluid">
                  <div className="row">
                    <ModalTitleContainer className="col-md-12">Filtrar</ModalTitleContainer>
                  </div>
                  <div className="row">
                    <StyledFilter className="col-md-12 modal">
                      <div className="col-md-12">
                        <Select
                          isSearchable={false}
                          className="select-control-container"
                          classNamePrefix="select-control"
                          name="sort"
                          onChange={this.handleOrder}
                          defaultValue={sorting[0]}
                          options={sorting}
                          value={selectedSorting}
                        />
                      </div>
                    </StyledFilter>
                  </div>
                  <div className="row">
                    <StyledFilter className="col-md-12 modal">
                      <div className="col-md-12">
                        <Select
                          isSearchable={false}
                          className="select-control-container"
                          classNamePrefix="select-control"
                          name="city"
                          onChange={this.handleCity}
                          defaultValue={cities[0]}
                          options={cities}
                          value={selectedCity}
                        />
                      </div>
                    </StyledFilter>
                  </div>
                  <div className="row">
                    <RestaurantContainer className="col-md-12">
                      <RestaurantFilters applyFilters={this.applyFilters} specialities={specialities} filters={filters} minDataToShow={minDataToShow} isModal={true} />
                    </RestaurantContainer>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <FilterButton onClick={this.closeModal}>Aplicar Filtros</FilterButton>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </StyledContainer>
      </div>
    )
  }
}

export default withLocation(RestaurantsSection)
