import styled from 'styled-components'
import media from '../../../../../styles/media'

export const ModalFooter = styled.div`
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
`
export const TextContainer = styled.div`
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 20px;
  img {
    width: 127px;
    margin: 20px;
  }
`
export const ModalComponentsWrapper = styled.div`
  .no-side-margins {
    margin-right: 0;
    margin-left: 0;
  }
  .footer-button {
    text-align: center;
  }
  ${media.sm`
    width: 556px;
    .footer-button {
      margin: 0 8rem;
      div {
        width: 100%;
      }
    }
  `}

  @media screen and (max-width: 767px) {
    width: 100%;
    .footer-button {
      margin: 0 2rem;
      div {
        width: 100%;
      }
    }
  }
`
